// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IamEnvironmentConfig } from "./environment-config";

export const iamEnvironment: IamEnvironmentConfig = {
  production: false,
  api: {
    'CARIAD': {
      url: 'https://api-s.azuf.cariad.vwgroup.com/gps',
      clientId: '2d0f166b-6a02-4f0e-81ff-f2054cb5d56e',
      authority: 'https://login.microsoftonline.com/c5f6f6e0-4c59-4aa1-bcd7-033f5f211b1c',
      scopes: ['api://2d0f166b-6a02-4f0e-81ff-f2054cb5d56e/All']
    },
    'VWGPROD': {
      url: 'https://api-stg-vwgprod.apps.vwgroup.com/gps',
      clientId: '076c8919-02ad-4f57-a45f-7149785499ce',
      authority: 'https://login.microsoftonline.com/9664fb57-6ab5-4cf3-8c69-2392f2ac06a3',
      scopes: ['api://076c8919-02ad-4f57-a45f-7149785499ce/All']
    },
    'VWGST': undefined,
    'PowerCo': {
      url: 'https://api-stg-powerco.apps.vwgroup.com/gps',
      clientId: 'a4084fc8-c985-454f-b74d-64addfe5d87d',
      authority: 'https://login.microsoftonline.com/a8cf543a-438a-4e26-96a1-a99cfa3c6b07',
      scopes: ['api://a4084fc8-c985-454f-b74d-64addfe5d87d/All']
    },
    'VWGITC': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    }
  }
};
